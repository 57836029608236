import { createUseStyles } from "react-jss";

/**
 * styles used in the component.
 */
export const RestoreModalStyles = createUseStyles({
  restoreName: {
    marginBottom: 20
  },
  fieldStyle: {
    marginTop: 10,
    marginBottom: 10
  },
  modalSubText: {
    fontSize: 14,
    fontWeight: 'normal'
  },
});