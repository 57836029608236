import { createUseStyles } from "react-jss";
import {
  Button,
  ButtonGroup,
  Grid,
  GridColumn,
  Heading,
  useSnackbar
} from "@walmart-web/livingdesign-components";
import { ChevronDown, ChevronRight } from "@walmart-web/livingdesign-icons";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DisplayValue from "../../../components/TextField/DisplayValue/DisplayValue";
import MatterComponent from "../../../components/MatterComponent/MatterComponent";
import { WHITE } from "../../../constants/colors";
import {
  PAID_DATE_LABEL,
  CREATION_DATE, CLOSURE_DATE,
} from "../../../constants/constants";
import { CANCEL, COLLAPSE_ALL, EDIT, EXPAND_ALL, SAVE } from "../../../constants/actionButtons";
import { MATTER_DETAILS_UPDATE_SUCCESS, MATTER_DEFINITIONS_ERROR_MESSAGE,GET_FACILITY_FAILED_MESSAGE } from "../../../constants/messages";
import { MOMENT_DATE_FORMAT } from "../../../constants/dateFormats";
import { SPACING } from "../../../constants/styleGuide";
import { compare, getMatterDefinitions, convertPANameToCode, convertPACodeToName, renderTernary, getErrorMessage } from "../../../helpers/utils";
import { revertMatterDetail, setMatterDetailSectionsBasedOnPracticeArea, setMatterDefinitions, setMatterDetail } from "../../../store/actions/matterAction";
import Subheading from '../../../components/TextField/SubHeading/Subheading';
import moment from 'moment';
import {
  patchService,
  getService
} from "../../../axios/axios";
import { MATTER_DETAILS_DEFINITION, MASTER_DATA } from "../../../constants/baseURLs";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton/LoadingSkeleton";
import { useForm } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const useStyles = createUseStyles({
  contentSpacingLeft16: {
    paddingLeft: SPACING.s16,
    paddingTop: SPACING.s4
  },
  buttonGroup: {
    alignSelf: "flex-end",
    marginLeft: "auto",
    paddingRight: '20px'
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: WHITE,
    padding: 16,
  },
  detailContainer: {
    paddingBottom: 20
  },
  accordionDetails: {
    width: "100%",
    justifyContent: "space-between",
  },
  contentSpacing16: {
    paddingTop: SPACING.s16,
  },
  accordion: {
    margin: "24px 0",
  },
  noResults: {
    width: "100%",
    margin: "100px auto",
    textAlign: "center",
    padding: "32px 24px 40px",
    "& h4": {
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
});

/**
* Component to return no results UI
*/
const NoResults = () => {
  const classes = useStyles();
  return (
    <div className={classes.noResults}>
      <Subheading as="h4" variant="1">
        No Results Found
      </Subheading>
    </div>
  )
}

const MatterDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState([]);
  const [edit, setEdit] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const { matterSectionDetails, matterDetailsTab, matterDetail, matterDefinitions} = useSelector((state) => state?.matter);
  const matterEditAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.matters?.details?.tabs?.details?.edit);
  const prefix = "MATTER_";
  const practiceArea = matterDetail?.matter?.practiceArea?.toLowerCase()?.split(" ")?.map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))?.join(' ');
  const matterID = matterDetail?.matter?.identifier;
  const { register, formState: { isValid, errors }, reset} = useForm({mode: "all"});
  const [loadingFields, setLoadingFields] = useState([]);
  const [cancelReload, setCancelReload] = useState(false);
  const [facilityData, setFacilityData] = useState({})
  const hasFacilityId = matterDetail?.matter?.facilityId;
  const practiceAreaNameToCode = useSelector(state => state?.matter?.practiceAreaNameToCode);
  const setMatterDetailsToRedux = (matterDetails) => {
    const sectionArray = [];

    // Generate array of section names
    matterDetails?.definition?.properties?.map(result => {
      if (sectionArray?.length === 0 || !sectionArray?.includes(result?.category)) {
        sectionArray.push(result?.category)
      }
    })
    matterDetails.sectionData = [...sectionArray]

    matterDetails?.definition?.properties?.map((result, index) => {
      const propertyName = result?.propertyName;
      if (propertyName in matterDetail?.matter) {
        matterDetails["definition"].properties[index]["value"] = matterDetail?.matter?.[propertyName];
      }else{
        matterDetails["definition"].properties[index]["value"] = null
      }
      dispatch(setMatterDetailSectionsBasedOnPracticeArea(matterDetails))
    });
  }

  useEffect(() => {
    // On fresh render of the component, revert any prior editing information that was present in the redux store
    dispatch(revertMatterDetail());
    reset()
    // Consolidate the matter details to then set them in the redux store
    consolidateMatterDetails();
  }, [matterDetail]);

  // This method is responsible for ensuring that the matter definition, data as well as associated fields from other modules are consolidated into a single object.
  const consolidateMatterDetails = () => {
    const matterDetailsResponse = {};
    if(!cancelReload)
    {
    setLoading(true);
    }
    setCancelReload(false);
    // If we already have the definition loaded in redux, don't make the API call for it
    const practiceAreaCode = prefix + convertPACodeToName(practiceArea, practiceAreaNameToCode);
    if (matterDefinitions && (practiceAreaCode in matterDefinitions)) {
      matterDetailsResponse["definition"] = matterDefinitions[practiceAreaCode];
      setLoading(false);
      setMatterDetailsToRedux(matterDetailsResponse);
    } else {
      // Else, we need to fetch the definitions, and then proceed
      getMatterDefinitions()
        .then((matterDefinitions) => {
          dispatch(setMatterDefinitions(matterDefinitions));
          // If we don't see the practice area in the matter definitions response, throw an error
          if (!(practiceAreaCode in matterDefinitions)) {
            setLoading(false);
            addSnack({
              message: MATTER_DEFINITIONS_ERROR_MESSAGE
            });
          } else {
            // Else we now have the definition loaded into redux, we can proceed
            matterDetailsResponse["definition"] = matterDefinitions[practiceAreaCode];
            setLoading(false);
            setMatterDetailsToRedux(matterDetailsResponse);
          }
        })
        .catch((error)=>{
          setLoading(false);
          addSnack({
            message: getErrorMessage(error)
          });
        });
    }
  }

  /**
   * Method to decide whether we should show a matter section
   */
  const showMatterSection = (sectionNodes) => {
    let returnValue = false;
    sectionNodes?.forEach((node) => {
      if(JSON.parse(decodeURI(node?.renderingHints))?.isOcpView)
      {
      returnValue = true;
      }
    })
    return returnValue;
  }


  /**
   * Gets called when user click on save button
   *
   * @public
   */
  const handleSaveClick = (event, index) => {
    const convertedMatterData =  matterDetailsTab;
    convertedMatterData["versionKey"] = "1";
    Object.keys(convertedMatterData)?.forEach((value) => {
      if (convertedMatterData[value] === 'Invalid date') {
        convertedMatterData[value] = null
      }
    })
    const body = convertedMatterData;
    const loadingItems = [];
    Object.keys(body)?.forEach((value) => {
      loadingItems.push(value);
    })
    setLoadingFields([...loadingItems]);
    patchService(
      MATTER_DETAILS_DEFINITION,
      `/matters/v1/${matterID}`,
      body
    )
      .then(() => {
        addSnack({
          message: MATTER_DETAILS_UPDATE_SUCCESS,
        });
        const updatedMatterDetail = {
          matter: {
            ...matterDetail?.matter,
            ...body
          }
        }
        setCancelReload(true)
        dispatch(setMatterDetail(updatedMatterDetail));
        setLoadingFields([]);

      })
      .catch((error) => {
        setLoadingFields([])
        addSnack({
          message: getErrorMessage(error)
        });
      });
    const sectionIndex = edit?.indexOf(index);
    if (index > -1) {
      edit?.splice(sectionIndex, 1);
      setEdit([...edit]);
    }
    event.stopPropagation();
  };

  /**
   * Gets called when user click on edit button
   *
   * @public
   */
  const handleEditClick = (event, index) => {
    setEdit([...edit, index]);
    if (expand?.indexOf(index) > -1) {
      event.stopPropagation();
    }
  };

  /**
   * Gets called when user click on cancel button
   *
   * @public
   */
  const handleCancelClick = (event, index) => {
    dispatch(revertMatterDetail())
    reset()
    const sectionIndex = edit?.indexOf(index);
    if (index > -1) {
      edit?.splice(sectionIndex, 1);
      setEdit([...edit]);
    }
    event.stopPropagation();
  };

  const convertDisplayValue = (title, value) => {
    if (title === PAID_DATE_LABEL) {
      return value ? `$${value}` : '-'
    }
    else if(title === CREATION_DATE || title === CLOSURE_DATE){
      return (value) ? moment(value).format(MOMENT_DATE_FORMAT): null;
    } else {
      return value;
    }
  }

  useEffect(() => {
    if (hasFacilityId) {
      getService(MASTER_DATA,
        `master-data/v1/facilities/${hasFacilityId}`
      ).then((response) => {
        setFacilityData(response?.data)
      }).catch((error) => {
        addSnack({ message: GET_FACILITY_FAILED_MESSAGE })
      })
    }
  }, [matterDetail?.matter?.facilityId])

  return (
    <div>
      {
        loading && <LoadingSkeleton />
      }
      {
      (!(loading) && matterSectionDetails?.sectionData?.length === 0) ?
      NoResults() :
      <div className={classes.detailContainer}>
      <div className={classes.toolbar}>
        <ButtonGroup>
          <Button
            id='matter-expand-all'
            data-testid='matter-expand-all'
            onClick={() =>
              setExpand([
                ...matterSectionDetails?.sectionData?.map((value, index) => index),
              ])
            }
          >
            {EXPAND_ALL}
          </Button>
          <Button id='matter-collapse-all' data-testid='matter-collapse-all' onClick={() => setExpand([])} >{COLLAPSE_ALL}</Button>
        </ButtonGroup>
      </div>
      {matterSectionDetails?.sectionData && matterSectionDetails?.sectionData?.map((section, index) => {
        const sectionNodes = matterSectionDetails?.definition?.properties?.filter(
          (node) => node?.category === section
        );
        const getNodeVal=(nodeInfo)=>{
          return nodeInfo?.value === false ? 'No' : convertDisplayValue(nodeInfo?.title, nodeInfo?.value)
        }
        if(showMatterSection(sectionNodes))
        {
        return (
          <Accordion
            className={classes.accordion}
            expanded={expand?.indexOf(index) > -1}
            onChange={() =>
              setExpand((expand) =>
                expand?.indexOf(index) > -1
                  ? [...expand?.filter((value) => value !== index)]
                  : [...expand, index]
              )
            }
            key={`${section?.toLowerCase().split(' ').join('-')}-accordian`}
          >
            <AccordionSummary
              // expandIcon={<Pencil />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              data-testid={`panel-header-${index}`}
            >
              {expand?.indexOf(index) > -1 ? (
                <ChevronDown size="large" />
              ) : (
                <ChevronRight size="large" />
              )}
              <div className={classes.contentSpacingLeft16}>
                <Heading as="h1" size="small" id={`${section?.toLowerCase().split(' ').join('-')}-accordian`}>
                  {section}
                </Heading>
              </div>
              <div className={classes.buttonGroup}>
                {edit?.includes(index) ? (
                  <ButtonGroup>
                    <Button variant="secondary" data-testid={`${section?.toLowerCase()}-cancel-button`} onClick={(e) => handleCancelClick(e, index)}>
                      {CANCEL}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!isValid}
                      onClick={(e) => handleSaveClick(e, index)}
                      data-testid={`${section?.toLowerCase()}-save-button`}
                      id={`${section?.toLowerCase().split(' ').join('-')}-save-button`}
                    >
                      {SAVE}
                    </Button>
                  </ButtonGroup>
                ) : ( renderTernary(
                  matterEditAccessibility,
                  <Button
                    variant="tertiary"
                    type="button"
                    onClick={(e) => handleEditClick(e, index)}
                    disabled={false}
                    data-testid={`${section?.toLowerCase()}-edit-button`}
                    id={`${section?.toLowerCase().split(' ').join('-')}-edit-button`}
                  >
                    {EDIT}
                  </Button>,
                  <React.Fragment></React.Fragment>
                  )
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {(section === "Location & Store Information" && hasFacilityId) ?
                <Grid className={classes.accordionDetails}>
                  <GridColumn sm={5}>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility Number'}
                        value={facilityData?.facilityNumber}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility Type'}
                        value={facilityData?.facilityType}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility City'}
                        value={facilityData?.facilityCity}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility State'}
                        value={facilityData?.facilityState}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility County'}
                        value={facilityData?.facilityCounty}
                        loadingFields={loadingFields}
                      />
                    </div>
                  </GridColumn>
                  <GridColumn sm={5}>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility Country'}
                        value={facilityData?.facilityCountry}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility Manager'}
                        value={facilityData?.facilityManager}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Facility Region'}
                        value={facilityData?.facilityRegion}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Regional Manager'}
                        value={facilityData?.facilityRegionalManager}
                        loadingFields={loadingFields}
                      />
                    </div>
                    <div className={classes.contentSpacing16}>
                      <DisplayValue
                        label={'Market Number'}
                        value={facilityData?.storeMarketNumber}
                        loadingFields={loadingFields}
                      />
                    </div>
                  </GridColumn>
                </Grid> :
                <Grid className={classes.accordionDetails}>
                  <GridColumn sm={5}>
                    {sectionNodes && sectionNodes
                      ?.filter((node) => JSON.parse(decodeURI(node?.renderingHints))?.pane === "left" && JSON.parse(decodeURI(node?.renderingHints))?.isDisplayed !== false && JSON.parse(decodeURI(node?.renderingHints))?.isOcpView !== false)
                      ?.sort(compare)
                      ?.map((node, idx) => {
                        const nodeValue = matterSectionDetails &&
                        matterSectionDetails?.definition?.properties?.[node?.title] ?
                        matterSectionDetails?.definition?.properties?.[node?.title] :
                        node?.value;
                        const nodeInfo = { ...node, value: nodeValue }
                        return (edit?.includes(index) ? (
                          <MatterComponent node={
                            nodeInfo
                          }
                          isDisabled={!JSON.parse(decodeURI(node?.renderingHints))?.isOcpEdit}
                          key={node?.title} errors={errors} register={register}/>
                        ) : <div className={classes.contentSpacing16} key={node?.title} >
                            {(JSON.parse(decodeURI(node?.renderingHints))?.isOcpView) ? (<DisplayValue
                              label={nodeInfo?.title}
                              loadingFields={loadingFields}
                              node={nodeInfo}
                              value={nodeInfo?.value === true ?
                                "Yes" : getNodeVal(nodeInfo)} />) : <React.Fragment></React.Fragment>}
                        </div>
                        )
                      }
                      )}
                  </GridColumn>
                  <GridColumn sm={5}>
                    {sectionNodes && sectionNodes
                      ?.filter((node) => JSON.parse(decodeURI(node?.renderingHints))?.pane === "right" && JSON.parse(decodeURI(node?.renderingHints))?.isDisplayed !== false && JSON.parse(decodeURI(node?.renderingHints))?.isOcpView !== false)
                      ?.sort(compare)
                      ?.map((node, idx) => {
                        const nodeValue = matterSectionDetails && matterSectionDetails?.definition?.properties?.[node?.title] ?
                        matterSectionDetails?.definition?.properties?.[node?.title] : node?.value;
                        const nodeInfo = { ...node, value: nodeValue }
                        return (edit?.includes(index) ? (
                          <MatterComponent
                          isDisabled={!JSON.parse(decodeURI(node?.renderingHints))?.isOcpEdit}
                          node={node} key={node?.title} errors={errors} register={register}/>
                        ) : <div className={classes.contentSpacing16} key={node?.title}>
                          {(JSON.parse(decodeURI(node?.renderingHints))?.isOcpView) ? (<DisplayValue label={nodeInfo?.title}
                          loadingFields={loadingFields}
                          node={nodeInfo}
                          value={nodeInfo?.value === true ? "Yes" : getNodeVal(nodeInfo)} />) : <React.Fragment></React.Fragment>}
                        </div>
                        )
                      }
                      )}
                  </GridColumn>
                  <GridColumn sm={12}>
                    {sectionNodes && sectionNodes
                      ?.filter((node) => JSON.parse(decodeURI(node?.renderingHints))?.pane === "fullWidth" && (JSON.parse(decodeURI(node?.renderingHints))?.isDisplayed !== false) && JSON.parse(decodeURI(node?.renderingHints))?.isOcpView !== false)
                      ?.sort(compare)
                      ?.map((node, idx) => {
                        const nodeValue = matterSectionDetails && matterSectionDetails?.definition?.properties?.[node?.title] ?
                        matterSectionDetails?.definition?.properties?.[node?.title] : node?.value;
                        const nodeInfo = { ...node, value: nodeValue }
                        return (edit?.includes(index) ? (
                          <MatterComponent
                          isDisabled={!JSON.parse(decodeURI(node?.renderingHints))?.isOcpEdit}
                          node={node} key={node?.title} errors={errors} register={register}/>
                        ) : <div className={classes.contentSpacing16} key={node?.title}>
                          {(JSON.parse(decodeURI(node?.renderingHints))?.isOcpView) ? (<DisplayValue label={nodeInfo?.title}
                          loadingFields={loadingFields}
                          node={nodeInfo}
                          value={nodeInfo?.value === true ? "Yes" : getNodeVal(nodeInfo)} />) : <React.Fragment></React.Fragment>}
                        </div>
                        )
                      }
                      )}
                  </GridColumn>
                </Grid>
              }
            </AccordionDetails>
          </Accordion>
        );
        }
        else
        {
          return <div key={`${section?.toLowerCase().split(' ').join('-')}-div`}></div>
        }
      })}
      </div>
    }
    </div>
  );
};

export default MatterDetails;
