import { Money } from "@livingdesign/icons";
import { Breadcrumb, Body, Popover, BreadcrumbItem, Button, Divider, Grid, GridColumn, Heading, Skeleton, Spinner, useSnackbar, TextArea, TextField } from "@walmart-web/livingdesign-components";
import { Pencil } from "@walmart-web/livingdesign-icons";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Tooltip from '@mui/material/Tooltip';
import { getService, patchService, postService } from "../../../../axios/axios";
import { SELECT_ADJUSTMENT_REASON, ADJUSTED_LINE_ITEM_DESCRIPTION, CS_SCOPES, LABEL_APPEALED } from "../../../../constants/constants";
import { NO_COMMENT_AVAILABLE, NO_REASON_AVAILABLE, RESTORE_SUCCCESS_MESSAGE } from "../../../../constants/messages";
import { MOMENT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { COMPOSITE_SEARCH_SERVICE, INVOICE_SERVICE } from "../../../../constants/baseURLs";
import { setLineItemsData, setNonAdjustedPaginationForLineItems, setAdjustedPaginationForLineItems } from "../../../../store/actions/invoiceActions";
import { getErrorMessage, onInputNumberKeyDown, renderTernary, onInputNumberChange } from "../../../../helpers/utils";
import MasterDataAutocomplete from "../../../../components/Autocomplete/MasterDataAutocomplete/MasterDataAutocomplete";
import { amountFormat, filterPayload } from "../../../../helpers/commonUtils";
import { CANCEL, DISCARD, RESTORE, SAVE } from "../../../../constants/actionButtons";
import { InvoiceLineItemDetailStyles } from "./ViewEditInvoiceLineItem.styles";
import { useLocation } from 'react-router-dom';
import { displayLocalDate, convertToUTCDateTime } from "../../../../helpers/utcUtils";
import { formCompositeSearchQuery, formCsFilterObject } from "../../../../helpers/csQueryUtils";
import RestoreModal from "../../../../components/Modal/RestoreModal/RestoreModal";
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from "@mui/material";

const InvoiceLineItem = (props) => {
	const classes = InvoiceLineItemDetailStyles();
	const [comment, setComment] = useState("");
	const [isOpen, setIsOpen] = useState(null);
	const [blockOpen, setBlockOpen] = useState(null);
	const [invoiceDetail, setInvoiceDetail] = useState({});
	const [loading, setLoading] = useState(false);
	const [tkClassificationLoading, setTKClassificationLoading] = useState(false);
	const [summaryLoading, setSummaryLoading] = useState(false);
	const { addSnack } = useSnackbar();
	const { invoiceId, lineItemId } = useParams();
	const { lineItemsData } = useSelector((state) => state?.invoice);
	const { state: { fromMatter, currentMatterTab } = {} } = useLocation();
	const matterDetail = useSelector((state) => state?.matter?.matterDetail);
	const dispatch = useDispatch();
	const { subLineItems } = lineItemsData;
	const activeSubLineItem = subLineItems?.filter(subLineItem => subLineItem?.isActive === true)?.[0];
	const [metaLineItem, setMetaLineItem] = useState({});
	const [isEditable, setIsEditable] = useState(false);
	const history = useHistory();

	//Net Amount
	const [calculatedNetAmount, setCalculatedNetAmount] = useState(activeSubLineItem?.netAmount);
	const [finalNetAmount, setFinalNetAmount] = useState(0);

	//Units
	const [finalUnits, setFinalUnits] = useState(0);

	//Rate
	const [finalRate, setFinalRate] = useState(0);
  	
	//Discounts
	const [finalDiscounts, setFinalDiscounts] = useState(0);
  	
	//Adjustments
	const [finalAdjustments, setFinalAdjustments] = useState(0);
	const [isAdjusted, setIsAdjusted] = useState(false)

  	//Reason
	const [reason, setReason] = useState('');
	const activeDiscountValue = (activeSubLineItem?.discountValue < 0) ? (-1 * activeSubLineItem?.discountValue) : activeSubLineItem?.discountValue;


	const [openRestoreModal, setOpenRestoreModal] = useState(false);
	const [restoreLineItemId, setRestoreLineItemId] = useState('');
	const [restoreParentLineItemId, setRestoreParentLineItemId] = useState('');
	const [restoreLoading, setRestoreLoading] = useState(false)
	const [openRestoreInfo, setOpenRestoreInfo] = useState({})
	const refs = React.useRef(lineItemsData?.subLineItems?.map(()=> createRef));

  const masterDetails = useSelector(state => state?.masterData?.masterDatas);
  const [selectedUTBMSDesc, setSelectedUTBMSDesc] = useState('')
  const [selectedActivityDesc, setSelectedActivityDesc] = useState('');
  const utbmsLookup = [...masterDetails?.expenseCode?.masterData || '', ...masterDetails?.phaseCode?.masterData || '', ...masterDetails?.taskCode?.masterData || '']
  const actLookup = [...masterDetails?.activityCode?.masterData || ''];
	const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.invoice?.details?.tabs?.lineItems);
	const adjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.adjustedLineItemsPaginationDetail);
  const nonAdjustedLineItemsPaginationDetail = useSelector((state) => state?.invoice?.nonAdjustedLineItemsPaginationDetail);

  /**
   * Function to get description of UTBMS and Activity code
   * @param {*} lookup
   * @param {*} code
   * @returns
   */
  const getCodedesc = (lookup, code) => {
    const selectedItem = lookup?.filter(obj => obj?.value === code)[0];
    return selectedItem?.description || '';
  }

  const ShowDescription =(activeSubLineItem)=>{
	if(loading) {
		return (<div className={classes.lineItemHeading}>
			<Skeleton height="26px" variant="rectangle" />
		</div>);
	} else if(activeSubLineItem?.description) {
		return (<div className={classes.lineItemHeading}>
			<p>
				{activeSubLineItem?.description}
			</p>
		</div>);
	} else {
		return <></>
	}
}

  useEffect(() => {
    if(metaLineItem?.hasOwnProperty("utbmsCode") || metaLineItem?.hasOwnProperty("activityCode")) {
      setSelectedUTBMSDesc(getCodedesc(utbmsLookup, metaLineItem?.utbmsCode));
      setSelectedActivityDesc(getCodedesc(actLookup, metaLineItem?.activityCode));
    }
	if(metaLineItem?.hasOwnProperty("timekeeperEmail") && !metaLineItem?.hasOwnProperty("classification")){
		getPeopleClassification()
	}
  }, [metaLineItem])

   // Disable edit for invoice level edited line item (without rate and unit)
   useEffect(() => {
    if(Object.keys(activeSubLineItem??{}).length > 0) {
      //to identify IE and IF type: unit will will be 1, rate will be 0 and utbms will be undefined
      const isDiscountType = activeSubLineItem?.unit == 1 && activeSubLineItem?.rate == 0 && activeSubLineItem?.utbmsCode === undefined
		const editConditions = activeSubLineItem?.unit !== undefined &&  // for overall invoice edit
		activeSubLineItem?.rate !== undefined && // check if unit , rate is not present
		activeSubLineItem?.description !== ADJUSTED_LINE_ITEM_DESCRIPTION // and description is system generated
		&& !isDiscountType;
        setIsEditable(Boolean(editConditions));
    }
  }, [activeSubLineItem])

	/**
	* Function for opening the edit toggle
	**/
	const toggleOpen = id => () => setIsOpen(
		isOpen => isOpen === id ? null : id,
	);

	const resetAllEditValues = () => {
		setIsOpen(null);
		setFinalRate(0);
		setFinalUnits(0);
		setFinalNetAmount(0);
		setFinalDiscounts(0);
		setFinalAdjustments(0);
		setCalculatedNetAmount(activeSubLineItem?.netAmount);
    	setReason('');
	}

	/**
	* Function for opening the block restore button
	**/
	const toggleButton = id => () => setBlockOpen(
		blockOpen => blockOpen === id ? null : id,
	);

	/**
   * This function is called when api call throws an error
   */
	 const handleError = (error) => {
		setLoading(false);
		  addSnack({
			message: getErrorMessage(error),
		  });
	  }

	/*
	* The function will be used for getting the invoice Detail
	*/
	const getInvoiceData = () => {
		setSummaryLoading(true)
		getService(INVOICE_SERVICE, `/invoices/v1/${invoiceId}/summary`)
			.then((res) => {
				const data = res?.data;
				setInvoiceDetail(data);
				setSummaryLoading(false)
			})
			.catch((error) => {
				setSummaryLoading(false)
				handleError(error)
			});
	}

	/*
	* The function will be used for getting the Line Items Detail
	*/
	const getLineItemsData = () => {
		getService(INVOICE_SERVICE, `/invoices/v1/line-items/${lineItemId}/sub-line-items`)
			.then((res) => {
				const data = res?.data;
				setLoading(false)
				setMetaLineItem(data?.subLineItems?.[0]);
				dispatch(setLineItemsData(data));
			})
			.catch((error) => {
				handleError(error)
			});
	}

	useEffect(() => {
		setLoading(true);
		setLineItemsData({})
		getInvoiceData();
		getLineItemsData();
	}, [])

	/**
	 * Gets called when clicked on Restore button.
	 */
	const restoreLineItem = (parentLineItemId, lineItemId) => {
		setRestoreLineItemId(lineItemId);
		setRestoreParentLineItemId(parentLineItemId);
		setOpenRestoreModal(true);
	};

	const handleRestoreModalClose = () => {	
		setOpenRestoreModal(false);
	}

	const confirmRestore = (payload) => {
		setRestoreLoading(true);
		const filteredBody=filterPayload(payload)
		patchService(INVOICE_SERVICE, `/invoices/v1/${activeSubLineItem?.invoiceMasterId}/sub-line-items/restore`, filteredBody)
		.then((res) => {
			setLoading(false);
			getLineItemsData();
			addSnack({
				message: RESTORE_SUCCCESS_MESSAGE,
			});
		})
		.catch((error) => {
			handleError(error);
		})
		.finally(()=>{
			setRestoreLoading(false)
			setOpenRestoreModal(false);
		});
	};

	/*
* The function will use for setting the form values
*/
	const valueChange = (type, value) => {
		switch(type) {
			case 'rate':
				setFinalRate(value);
				break;
			case 'units':
				setFinalUnits(value);
				break;
			case 'discounts':
				setFinalDiscounts(value);
				break;
		}
	}

  const calculateNetAmount = (noDiscounts=false, noAdjustments=false) => {
		const unit = (finalUnits === 0) ? activeSubLineItem?.unit ?? 0 : finalUnits;
		const rate = (finalRate === 0) ? activeSubLineItem?.rate ?? 0 : finalRate;
		const discount = (finalDiscounts === 0) ? activeSubLineItem?.discountValue ?? 0 : (-1 * Number(finalDiscounts));
		const adjustments = activeSubLineItem?.adjustmentValue ?? 0;

		const finalNetAmount = (unit * rate) + (noDiscounts ? 0 : discount) + (noAdjustments ? 0 : Number(adjustments));
		return Number(finalNetAmount?.toFixed(2))
	}

	/**
	 * This will use to calculate net amount
	 */
	useEffect(() => {
		const netAmount = calculateNetAmount();
		if (netAmount > 0) {
			setCalculatedNetAmount(netAmount);
		} else {
			setFinalDiscounts(0)
			setFinalAdjustments(0)
			if (activeSubLineItem?.netAmount > 0 && netAmount <= 0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalUnits])

	useEffect(() => {
		const netAmount = calculateNetAmount();
		if (netAmount > 0) {
			setCalculatedNetAmount(netAmount);
		} else {
			setFinalDiscounts(0)
			setFinalAdjustments(0)
			if (activeSubLineItem?.netAmount > 0 && netAmount <= 0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalRate])

  useEffect(() => {
		const netAmount = calculateNetAmount();
		if(netAmount > 0 ) {
			setCalculatedNetAmount(netAmount);
		} else {
			setFinalDiscounts(0)
			if(activeSubLineItem?.netAmount > 0 && netAmount <=0) {
				addSnack({
					message: "Net Amount should be greater than 0",
				});
			}
		}
	}, [finalDiscounts])

	useEffect(()=>{
		let obj = {}
		lineItemsData?.subLineItems?.map((item)=>obj[item.id] = false)
		setOpenRestoreInfo(obj)
	}, [lineItemsData?.subLineItems?.length])

  // Reset lineItemsData from store to prevent flashing of previous values
	useEffect(() => {
		return () => {
			dispatch(setLineItemsData([]));
		}
	}, [])

	/**
	 * Function will use to create new Sub-Line Item
	 */
	const createSubLineItem = () => {
		const body = {
			lineItemType: metaLineItem?.lineItemType,
			lineItemDate: metaLineItem?.lineItemDate,
			description: activeSubLineItem?.description,
			utbmsCode: metaLineItem?.utbmsCode,
			activityCode: metaLineItem?.activityCode,
			unit: (finalUnits === 0) ? activeSubLineItem?.unit : finalUnits,
			rate: (finalRate === 0) ? activeSubLineItem?.rate : finalRate,
			tkId: activeSubLineItem?.tkId,
			timekeeperUserId: activeSubLineItem?.timekeeperUserId,
			timekeeperEmail: activeSubLineItem?.timekeeperEmail,
			timekeeperName: activeSubLineItem?.timekeeperName,
			adjustmentValue: (finalAdjustments === 0) ? activeSubLineItem?.adjustmentValue : finalAdjustments,
			reason: reason,
			comment: comment,
			discountType: "",
			discountValue: (finalDiscounts === 0) ? activeSubLineItem?.discountValue : (-1 * finalDiscounts),
			parentId: activeSubLineItem?.parentId
		};
		setLoading(true);
		const filteredBody=filterPayload(body)
		postService(INVOICE_SERVICE, `/invoices/v1/${activeSubLineItem?.invoiceMasterId}/sub-line-items`, filteredBody)
			.then((res) => {
				setLoading(false);
				getLineItemsData();
				resetAllEditValues();
				setPaginationItems(metaLineItem?.lineItemType)
				setComment('');
				addSnack({
					message: "Submitted successfully",
				});
			})
			.catch((error) => {
				handleError(error)
			});
	}

	const setPaginationItems = (lineItemType) => {
		if(lineItemType == 'fee'){
			if(adjustedLineItemsPaginationDetail?.adjustedFeeCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedFeeCount !== 0){
				const currpage = ((adjustedLineItemsPaginationDetail?.adjustedFeeCount - 1)%adjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (adjustedLineItemsPaginationDetail?.currentPage !== 0)?(adjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:adjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: adjustedLineItemsPaginationDetail?.tabName,
					adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount - 1,
					adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount
				}))
			}
			if(nonAdjustedLineItemsPaginationDetail?.adjustedFeeCount !== undefined || nonAdjustedLineItemsPaginationDetail?.adjustedFeeCount !== 0){
        const currpage = ((nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount - 1)%nonAdjustedLineItemsPaginationDetail?.rowsPerPage);
        const zeroPageCheck = (nonAdjustedLineItemsPaginationDetail?.currentPage !== 0)?(nonAdjustedLineItemsPaginationDetail?.currentPage-1):0;
				dispatch(setNonAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:nonAdjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
					nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount -1,
					nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount
				}))
			}
		}else{
			if(adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== undefined || adjustedLineItemsPaginationDetail?.adjustedExpenseCount !== 0){
        const currpage = ((adjustedLineItemsPaginationDetail?.adjustedExpenseCount - 1)%adjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (adjustedLineItemsPaginationDetail?.currentPage !== 0)?(adjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:adjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: adjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: adjustedLineItemsPaginationDetail?.tabName,
					adjustedFeeCount: adjustedLineItemsPaginationDetail?.adjustedFeeCount,
					adjustedExpenseCount: adjustedLineItemsPaginationDetail?.adjustedExpenseCount - 1
				}))
			}
			if(nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== undefined || nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount !== 0){
				const currpage = ((nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount - 1)%nonAdjustedLineItemsPaginationDetail?.rowsPerPage)
        const zeroPageCheck = (nonAdjustedLineItemsPaginationDetail?.currentPage !== 0)?(nonAdjustedLineItemsPaginationDetail?.currentPage-1):0;
        dispatch(setNonAdjustedPaginationForLineItems({
					currentPage: (currpage === 0)?zeroPageCheck:nonAdjustedLineItemsPaginationDetail?.currentPage,
					rowsPerPage: nonAdjustedLineItemsPaginationDetail?.rowsPerPage,
					tabName: nonAdjustedLineItemsPaginationDetail?.tabName,
					nonAdjustedFeeCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedFeeCount,
					nonAdjustedExpenseCount: nonAdjustedLineItemsPaginationDetail?.nonAdjustedExpenseCount -1
				}))
			}
		}
	}

	const discardChanges = () => {
		history.goBack()
	}

	/**
	 * Function to call associate people list Service and set timekeeper Classification
	 * @param {String} text
	 */
	const getPeopleClassification = () => {
	  setTKClassificationLoading(true)
	  const tkEmail = metaLineItem?.timekeeperEmail
	  const defaultQuery = {
		"filters": [
		  formCsFilterObject("isExternalUser", "match", true),
		  formCsFilterObject("roleCodes", "raw", "OC_TIMEKEEPER"),
		  formCsFilterObject("emailId", "match", tkEmail),
		],
		"operation": "AND",
		"properties": null
	  }
	  const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, {}, defaultQuery);
	  postService(
		COMPOSITE_SEARCH_SERVICE,
		`/composite-search/v1?returnCsv=false`,
		query
	  )
		.then((res) => {
		  setTKClassificationLoading(false)
		  const classification = res?.data?.people?.data[0]?.classification;
		  setMetaLineItem({...metaLineItem,classification});
		})
		.catch((error) => {
		  setTKClassificationLoading(false);
		});
	};
	
	const handlePopoverOpen = (e,idx) => {
		e?.stopPropagation();
		console.log(openRestoreInfo)
		setOpenRestoreInfo((prev)=>({...prev, [idx]: true}))
	}

	const handlePopoverClose = () => {
		let obj = {}
		lineItemsData?.subLineItems?.map((item)=>obj[item.id] = false)
		setOpenRestoreInfo(obj)
	}

	const restoreDetails = (subLineItem) => {
		const idx = subLineItem.id;
		return (
			<div className={classes.lineItemText}>
				<Grid>
					<GridColumn sm={12} md={12} lg={12} id={`current-vesion-restore-reason-${idx}`} className={classes.centerDiv}>
							<strong id="current-restore-text" className={classes.restoreText}>
								{renderTernary(subLineItem?.restoredBy, subLineItem?.restoredBy+" ", "-")}
							</strong>
							{` restored on ${renderTernary(subLineItem?.restoredOn, formatDate(subLineItem?.restoredOn)+" ", "-")}`}
						<Popover
							content={
								<div style={{ textAlign: "left", width: "max-content", maxWidth: "300px" }}>
									<Body
										as="p"
										size="small"
										data-testid="restore-info-content"
									>
										<p>{renderTernary(subLineItem?.restoreReason, "Reason: " + subLineItem?.restoreReason, "")} </p>
										<p>{renderTernary(subLineItem?.restoreComment, "Comment: " + subLineItem?.restoreComment, "")}</p>
									</Body>
								</div>
							}
							data-testid="restore-info-popover"
							isOpen={openRestoreInfo[idx]}
							hasNubbin
							onClose={() => handlePopoverClose()}
							position="top"
							triggerRef={(el)=>(refs && refs.current ? refs.current[idx] = el : null)}
							>
							<Button
								ref={(el)=>(refs && refs.current ? refs.current[idx] = el : null)}
								data-testid="restore-info-btn"
								onClick={(e)=>handlePopoverOpen(e,idx)}
								variant="tertiary"
							>
								<InfoIcon fontSize="small"/>
							</Button>
							</Popover>
					</GridColumn>
				</Grid>
			</div>
		)
	}

	const loadLineItemDetails = (loading, label, value, isAmount) => {
		const displayValue = value ? (isAmount ? amountFormat(value) : value)  : (isAmount ? "0.00" : "0")
		return (<GridColumn sm={12} md={3} lg={3}>
			<div className={classes.lineItemLabel}>
				{label}
			</div>
			<div>
			{loading ? <span><Skeleton width="20px" height="20px" variant="rectangle" /></span>: displayValue }
			</div>
		</GridColumn>)

	}

	const formatDate = (date) => {
		const formattedDate = convertToUTCDateTime({date: date?.split(" ")[0], time: date?.split(" ")[1]}).slice(0,-3)
		return formattedDate;
	}

	return (
		<div className={classes.container}>
      		{((finalNetAmount !== 0 || finalUnits !== 0 || finalRate !== 0 || finalDiscounts !== 0 || finalAdjustments !== 0  ) ?
				<div className={classes.unsaveSection} data-testid="unsave-change-dialog">
					<h3 className={classes.unsaveText}>Unsaved Changes</h3>
					<div className={classes.unsaveButtons}>
						<Button
							className={classes.discardButtons}
							size="small"
							variant="secondary"
							data-testid="discard-btn"
							onClick={discardChanges}
						>
							{DISCARD}
						</Button>
						<Button
							size="small"
							variant="primary"
							data-testid="save-btn"
							id="save-btn"
							onClick={createSubLineItem}
							disabled={(finalNetAmount === 0
								&& finalUnits === 0
								&& finalRate === 0
								&& finalDiscounts === 0
								&& finalAdjustments === 0)}
						>
							{loading ? <Spinner color="white" size="small" className={classes.spinner}/> : SAVE}
						</Button>
					</div>
				</div> :
				<></>
			)}
			<div className={classes.breadcrumbWrapper}>
				{fromMatter ?
					<Breadcrumb>
						<BreadcrumbItem
							data-testid="invoice-nav-matter"
							onClick={() => history.push(`/`)}
						>
							Matters</BreadcrumbItem>
						<BreadcrumbItem
							data-testid="invoice-nav-matter-detail"
							onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`)}
						>
							#{matterDetail?.matter?.matterNumber}</BreadcrumbItem>
						<BreadcrumbItem
							data-testid="invoice-nav-matter-invoice-list"
							onClick={() => history.push(`/matter/${matterDetail?.matter?.identifier}`,
								{ currentMatterTab: currentMatterTab, currentFinancialTab: 1 })}>Financials</BreadcrumbItem>
						<BreadcrumbItem
							data-testid="lineitem-invoice-number"
							onClick={() => history.push(`/invoice/${invoiceDetail?.id}`)}
						>
							#{invoiceDetail?.organizationInvoiceNumber}
						</BreadcrumbItem>
								<BreadcrumbItem
									data-testid="lineitem-invoice-description"
									isCurrent>
									{renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, 'Line Item')}
								</BreadcrumbItem>
						</Breadcrumb>
					: <Breadcrumb>
						<BreadcrumbItem
							data-testid="lineitem-financial"
							onClick={() => history.push(`/financial`, { currentFinancialTab: 1 })}>
							Financial
						</BreadcrumbItem>
						<BreadcrumbItem
							data-testid="lineitem-invoice-number"
							onClick={() => history.push(`/invoice/${invoiceDetail?.id}`)}
						>
							#{invoiceDetail?.organizationInvoiceNumber}
						</BreadcrumbItem>
								<BreadcrumbItem
									data-testid="lineitem-invoice-description"
									isCurrent>
									{renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, 'Line Item')}
								</BreadcrumbItem>
					</Breadcrumb>
				}
			</div>
			<Grid>
				<GridColumn sm={12} md={9} lg={9}>
					<div className={classes.leftWrapper}>
						<Grid >
							<GridColumn sm={12} md={12} lg={12}>
									<Heading as="h1" size="medium" weight={500} className={classes.centerDiv}>
										Line Item #{
											loading?
											<span><Skeleton width="20px" height="20px" variant="rectangle" /></span>:
											(lineItemsData?.subLineItems?.map((el) => el.id).indexOf(activeSubLineItem?.id))+1
										}
									</Heading>
									{ShowDescription(activeSubLineItem)}
							</GridColumn>
						</Grid>
						{
							isOpen === 1 ?
							<div className={classes.lineItemDetail}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											<Heading as="h1" size="small" weight={700}>
												Edit Line Item
											</Heading>
										</div>
									</GridColumn>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											<Button
												className={classes.editCancel}
												onClick={() => resetAllEditValues()}
												data-testid="edit-cancel-btn"
											>{CANCEL}</Button>
										</div>
									</GridColumn>
								</Grid>
								<div className={classes.divider}>
									<Divider />
								</div>

								<div className={classes.whiteRow}>
									<div>
										<p>Adjustments Reason</p>
										<MasterDataAutocomplete
											value={reason}
											setValue={(value) => { setReason(value?.value) }}
											dataType={"invoiceAdjustmentReason"}
											testid="adjustments-reason"
											placeholderValue={SELECT_ADJUSTMENT_REASON}
										/>
									</div>
									<div>
										<p>Comment</p>
										<TextArea
											label={false}
											type="text"
											value={comment}
											onChange={(e) => setComment(e.target.value)}
											data-testid="comment"
											id="comment"
											maxLength={250}
											fullWidth={true}
										/>
									</div>
									<Grid>
										<GridColumn sm={12} md={12} lg={12}>
											<div className={classes.inputWrap}>
												<TextField
													label={false}
													type="text"
													value={amountFormat(calculatedNetAmount)}
													leadingIcon={<><Money size="medium" /> Net Amount</>}
													data-testid="net-amount-value"
													disabled={true}
												/>
											</div>
										</GridColumn>
									</Grid>
									<Grid>
										<GridColumn sm={12} md={6} lg={6}>
											<div className={classes.inputWrap}>
												<TextField
													label={false}
													type="number"
													value={finalRate === 0 ? activeSubLineItem?.rate : finalRate}
													onKeyDown={(e) => onInputNumberKeyDown(e)}
													onChange={(e) => {
														valueChange('rate', e.target.value)
													}}
													leadingIcon={<><Money size="medium" /> Rate</>}
													data-testid="rate-value"
													id="rate-value"
													textFieldProps={{
														min:0
													}}
													disabled={!(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable)}
													className={classes.leftInputWrap}
													onWheel={(e) => { e.target.blur() }}
												/>
											</div>
										</GridColumn>
										<GridColumn sm={12} md={6} lg={6}>
											<div className={classes.inputWrap}>
												<TextField
													label={false}
													type="number"
													value={finalUnits === 0 ? activeSubLineItem?.unit : finalUnits}
													onKeyDown={(e) => onInputNumberKeyDown(e)}
													onChange={(e) => {
														valueChange('units', e.target.value)
													}}
													leadingIcon={<> Units</>}
													data-testid="value"
													className={classes.rightInputWrap}
													id="units-value"
													disabled={!(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable)}
													onWheel={(e) => { e.target.blur() }}
												/>
											</div>
										</GridColumn>
									</Grid>
									<Grid>
										<GridColumn sm={12} md={6} lg={6}>
											<div className={classes.inputWrap}>
												<TextField
													label={false}
													type="number"
													value={finalDiscounts === 0 ? activeDiscountValue ?? 0 : finalDiscounts}
													onKeyDown={(e) => onInputNumberKeyDown(e)}
													onChange={(e) => {
														valueChange('discounts', e.target.value)
													}}
													leadingIcon={<><Money size="medium" /> Discounts</>}
													data-testid="discounts-value"
													className={classes.leftInputWrap}
													disabled={!(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable && accessibility?.editDiscount)}
													onWheel={(e) => { e.target.blur() }}
												/>
											</div>
										</GridColumn>
										<GridColumn sm={12} md={6} lg={6}>
											<div className={classes.inputWrap}>
												<TextField
													label={false}
													type="number"
													value={finalAdjustments === 0 ? activeSubLineItem?.adjustmentValue ?? 0 : finalAdjustments}
													leadingIcon={<><Money size="medium" /> Adjustments</>}
													data-testid="adjustments-value"
													className={classes.rightInputWrap}
													disabled={true}
												/>
											</div>
										</GridColumn>
									</Grid>
								</div>
							</div>: 
							<div className={classes.lineItemDetail}>
								<Grid>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemLeftText}>
											<Heading as="h1" size="small" weight={700}>
												Line Item Details
											</Heading>
										</div>
									</GridColumn>
									<GridColumn sm={12} md={6} lg={6}>
										<div className={classes.lineItemRightText}>
											{renderTernary(invoiceDetail?.status?.toLowerCase() === "appealed" && isEditable,
												<Button
													className={classes.editIcon}
													onClick={toggleOpen(1)}
													size="small"
												>
													<Pencil data-testid="edit-btn" id="edit-btn" />
													<span style={{marginLeft: "4px", fontSize:"15px"}}><strong>Edit</strong></span>
												</Button>,
												<></>
											)}
										</div>
									</GridColumn>
								</Grid>
								<div className={classes.divider}>
									<Divider />
								</div>								
								<div className={classes.whiteRow}>
									<Grid>
										{loadLineItemDetails(loading, "$ Net Amount", amountFormat( activeSubLineItem?.netAmount), true)}
										{loadLineItemDetails(loading, "$ Rate", activeSubLineItem?.rate, true)}
										{loadLineItemDetails(loading, "Units", activeSubLineItem?.unit, false)}
									</Grid>
									<Grid className={classes.dividerStyle}>
										{loadLineItemDetails(loading, "$ Discount", activeDiscountValue, true)}
										{loadLineItemDetails(loading, "$ Adjustments", activeSubLineItem?.adjustmentValue, true)}
									</Grid>
								</div>
							</div>
						}
						<div className={classes.divider}>
							<Divider />
						</div>
						<div>
							{(subLineItems?.length >= 1) ?
								<div className={classes.versionHeader}>
									<Grid>
										<GridColumn sm={12} md={8} lg={9}> <Heading as="h1" size="small" weight={500}>Current Version</Heading></GridColumn>
										<GridColumn sm={12} md={1} lg={1}>Unit</GridColumn>
										<GridColumn sm={12} md={1} lg={1}>$ Rate</GridColumn>
										<GridColumn sm={12} md={2} lg={1}>$ Net Amt</GridColumn>
									</Grid>
								</div> : <></>}

							{(subLineItems?.map((activeSubLineItem, index) =>
								<div key={activeSubLineItem.id}>
									{
										(activeSubLineItem?.isActive === true) ?
											<div className={classes.currentVersionDetail} data-testid="version-div">
												<Grid>
													<GridColumn sm={12} md={8} lg={9}>
														<Heading as="h1" size="small" weight={500} id="current-version-description">
															{`${renderTernary(activeSubLineItem?.version, parseFloat(activeSubLineItem?.version).toFixed(1), "")} ${renderTernary(activeSubLineItem?.description, activeSubLineItem?.description, "No Description Available")}`}
														</Heading>
													</GridColumn>
													<GridColumn sm={12} md={1} lg={1} id="current-version-unit">
														{renderTernary(activeSubLineItem?.unit, activeSubLineItem?.unit, 0)}
													</GridColumn>
													<GridColumn sm={12} md={1} lg={1} id="current-version-rate">
														{renderTernary(activeSubLineItem?.rate, activeSubLineItem?.rate, 0)}
													</GridColumn>
													<GridColumn sm={12} md={2} lg={1}>
														<Grid>
															<GridColumn sm={12} md={12} lg={12} id="current-version-netAmount">
																{renderTernary(activeSubLineItem?.netAmount, activeSubLineItem?.netAmount, 0)}
															</GridColumn>
															<GridColumn sm={12} md={12} lg={12} id="current-version-discountValue">
																({renderTernary(activeSubLineItem?.discountValue, activeSubLineItem?.discountValue, 0)})
															</GridColumn>
															<GridColumn sm={12} md={12} lg={12} id="current-version-adjustmentValue">
																({renderTernary(activeSubLineItem?.adjustmentValue, activeSubLineItem?.adjustmentValue, 0)})
															</GridColumn>
														</Grid>
													</GridColumn>
												</Grid>
												{activeSubLineItem?.restoreReason && restoreDetails(activeSubLineItem)}
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id="current-version-reason">
															{renderTernary(activeSubLineItem?.reason, activeSubLineItem?.reason, NO_REASON_AVAILABLE)}
														</GridColumn>
													</Grid>
												</div>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id="current-version-comment">
															{renderTernary(activeSubLineItem?.comment, activeSubLineItem?.comment, NO_COMMENT_AVAILABLE)}
														</GridColumn>
													</Grid>
												</div>
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={10} lg={11} id="current-version-createdTs">
															<strong id="current-version-createdBy">
																{renderTernary(activeSubLineItem?.createdBy, activeSubLineItem?.createdBy, "-") }
															</strong>
														  {` Submitted on ${renderTernary(activeSubLineItem?.createdTs, formatDate(activeSubLineItem?.createdTs)+" ", "-")}`}
                            							</GridColumn>
														<GridColumn sm={12} md={2} lg={1}>-</GridColumn>
													</Grid>
												</div>
											</div> :
											<></>
									}
								</div>))}
						</div>

						<div>
							{(subLineItems?.length > 1) ?
								<div className={classes.versionHeader}>
									<Grid>
										<GridColumn sm={12} md={8} lg={9}> <Heading as="h1" size="small" weight={500} id="Previous-Edits">Previous Edits</Heading></GridColumn>
									</Grid>
								</div> : <></>}

							{subLineItems?.map((inActiveSubLineItem, idx) =>
								<div key={inActiveSubLineItem?.id}>
									{
										(inActiveSubLineItem?.isActive === false) ?
											<div className={classes.previousVersionDetail} onClick={toggleButton(idx)} data-testid="togglebutton">
												<Grid id={"previous-version"}>
													<GridColumn sm={12} md={8} lg={9}> 
														<Heading as="h1" size="small" weight={500} id={`previous-version-description-${idx}`}>
															{`${renderTernary(inActiveSubLineItem?.version, parseFloat(inActiveSubLineItem?.version).toFixed(1), "")} ${renderTernary(inActiveSubLineItem?.description, inActiveSubLineItem?.description, "No Description Available")}`}
														</Heading>
													</GridColumn>
													<GridColumn sm={12} md={1} lg={1} id={`previous-version-unit-${idx}`}>
                            							{renderTernary(inActiveSubLineItem?.unit, inActiveSubLineItem?.unit, 0)}
                          							</GridColumn>
													<GridColumn sm={12} md={1} lg={1} id={`previous-version-rate-${idx}`}>
														{renderTernary(inActiveSubLineItem?.rate, inActiveSubLineItem?.rate, 0)}
													</GridColumn>
													<GridColumn sm={12} md={2} lg={1}>
														<Grid>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-netAmount-${idx}`}>
																{renderTernary(inActiveSubLineItem?.netAmount, inActiveSubLineItem?.netAmount, 0)}
															</GridColumn>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-discountValue-${idx}`}>
																({renderTernary(inActiveSubLineItem?.discountValue, inActiveSubLineItem?.discountValue, 0)})
															</GridColumn>
															<GridColumn sm={12} md={12} lg={12} id={`previous-version-adjustmentValue-${idx}`}>
																({renderTernary(inActiveSubLineItem?.adjustmentValue, inActiveSubLineItem?.adjustmentValue, 0)})
															</GridColumn>
														</Grid>
													</GridColumn>
												</Grid>
												{inActiveSubLineItem?.restoreReason && restoreDetails(inActiveSubLineItem)}
												<div className={classes.lineItemText}>
													<Grid>
														<GridColumn sm={12} md={12} lg={12} id={`previous-version-reason-${idx}`}>
															{renderTernary(inActiveSubLineItem?.reason, inActiveSubLineItem?.reason, "No Reason Available")}
														</GridColumn>
													</Grid>
												</div>
												<Grid>
													<GridColumn sm={12} md={10} lg={11} id={`previous-version-createdTs-${idx}`}>
														<strong id={`previous-version-createdBy-${idx}`}>
                             								 {renderTernary(inActiveSubLineItem?.createdBy, inActiveSubLineItem?.createdBy, "-")}
														</strong>
														{` Submitted on ${renderTernary(inActiveSubLineItem?.createdTs, formatDate(inActiveSubLineItem?.createdTs)+" ", "-")}`}
													</GridColumn>
													<GridColumn sm={12} md={2} lg={1}>-</GridColumn>
												</Grid>
												<div className={classes.button}>
													{blockOpen === idx ?
														<div>
															<div className={classes.divider}>
																<Divider />
															</div>
															<Button
																size="medium"
																variant="primary"
																onClick={() => restoreLineItem(inActiveSubLineItem?.parentId, inActiveSubLineItem?.id)}
																data-testid="restore-btn"
																id={`restore-btn-${idx}`}
																disabled = {invoiceDetail?.status?.toLowerCase() !== LABEL_APPEALED || summaryLoading}
															>
																{summaryLoading ? <Spinner size="small" color="white" /> : RESTORE}
															</Button>
														</div> : <></>}
												</div>
											</div> : <></>
									}
								</div>)}
						</div>
					</div>
				</GridColumn>
				<GridColumn sm={12} md={3} lg={3}>
					<div className={classes.rightWrapper}>
						<Grid>
							<GridColumn>
								<Heading as="h6" size="small" weight="700">
								<div className={classes.metaDataText}>
									Metadata
								</div>
								</Heading>
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									Date
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{metaLineItem?.lineItemDate ? metaLineItem?.lineItemDate && moment(metaLineItem?.lineItemDate).format(MOMENT_DATE_FORMAT) : "-"}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									Type
								</div></GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(metaLineItem?.lineItemType, metaLineItem?.lineItemType, "-")}
									</div>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									UTBMS
								</div></GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedUTBMSDesc}>
                    <div className={classes.rightText}>
                      {renderTernary(metaLineItem?.utbmsCode, metaLineItem?.utbmsCode, "-")}
                    </div>
                  </Tooltip>
								}
							</GridColumn>
						</Grid>
						<Grid>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									ACT
								</div></GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedActivityDesc}>
										<div className={classes.rightText}>
										{renderTernary(metaLineItem?.activityCode, metaLineItem?.activityCode, "-")}
										</div>
                  					</Tooltip>
								}
							</GridColumn>
						</Grid>
						<Divider className={classes.dividerStyle}/>
						<Grid>
							<GridColumn>
								<Heading as="h6" size="small" weight="700">
									<div className={classes.metaDataText}>
										Submitted Line Items:
									</div>
								</Heading>
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									$ Net Amount:
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{activeSubLineItem?.netAmount ? amountFormat(activeSubLineItem?.netAmount) : "0.00"}
									</div>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									Units:
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{activeSubLineItem?.unit ? activeSubLineItem?.unit : "0"}
									</div>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									$ Rate:
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedUTBMSDesc}>
										<div className={classes.rightText}>
											{activeSubLineItem?.rate ? amountFormat(activeSubLineItem?.rate) : "0.00"}
										</div>
									</Tooltip>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									$ Discounts:
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<Tooltip title={selectedActivityDesc}>
										<div className={classes.rightText}>
											{activeDiscountValue ? amountFormat(activeDiscountValue) : "0.00"}
										</div>
									</Tooltip>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									$ Adjustments:
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{activeSubLineItem?.adjustmentValue ? amountFormat(activeSubLineItem?.adjustmentValue) : "0.00"}
									</div>
								}
							</GridColumn>
						</Grid>
						<Divider className={classes.dividerStyle}/>
						<Grid>
							<GridColumn>
								<Heading as="h6" size="small" weight="700">
									<div className={classes.metaDataText}>
										Timekeeper Details:
									</div>
								</Heading>
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									Name
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(activeSubLineItem?.timekeeperName, activeSubLineItem?.timekeeperName, "-")}
									</div>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									Classification
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{tkClassificationLoading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{activeSubLineItem?.classification ? activeSubLineItem?.classification : "-"}
									</div>
								}
							</GridColumn>
							<GridColumn sm={4}>
								<div className={classes.leftText}>
									ID
								</div>
							</GridColumn>
							<GridColumn sm={8}>
								{loading === true ?
									<div className={classes.rightText}>
										<Skeleton height="18px" variant="rectangle" />
									</div> :
									<div className={classes.rightText}>
										{renderTernary(activeSubLineItem?.tkId, activeSubLineItem?.tkId, "-")}
									</div>
								}
							</GridColumn>
						</Grid>
					</div>
				</GridColumn>
			</Grid>
			{openRestoreModal && 
				<RestoreModal
					title="Line Item"
					restoreLoading={restoreLoading}
					isOpen={openRestoreModal}
					onCancel={handleRestoreModalClose}
					onRestore={confirmRestore}
					parentLineItemId={restoreParentLineItemId}
					lineItemId={restoreLineItemId}
				/>}
		</div>

	);
};

export default InvoiceLineItem;
