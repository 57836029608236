import {
	Button,
	ButtonGroup,
	Grid,
	GridColumn,
	Modal, Spinner,
    Select,
    TextArea,
    Body
} from "@walmart-web/livingdesign-components";
import React from "react";
import { CANCEL, CONFIRM_RESTORE } from "../../../constants/actionButtons";
import { RestoreModalStyles } from "./RestoreModal.styles";
import { RESTORE_REASONS } from "../../../constants/constants";
/**
 * Restore modal Actions
 */
const RestoreModalActions = (props) => {
	const {onCancel, onRestore, payload, restoreLoading} = props;
	return (
		<ButtonGroup>
			<Button
				data-testid="goback-btn"
				id="goback-btn"
				size="small"
				onClick={onCancel}
			>
				{CANCEL}
			</Button>
			<Button
				variant="primary"
				size="small"
				data-testid="continue-btn"
				id="export-continue-btn"
				onClick={()=>onRestore(payload)}
				disabled={restoreLoading}
			>
				{restoreLoading? <Spinner color="gray" size="small" />: <>{CONFIRM_RESTORE}</>}
			</Button>
		</ButtonGroup>
	);
};

const FieldLabel = ({ label, isRequired }) => {
  return (<Body as="p" size="small">
    <strong>{`${label}${isRequired ? '*' : ''}:`}</strong>
  </Body>)
}
/*
*This Modal enables user to restore the line items to previous versions
*/
const RestoreModal = (props) => {
	const {
		title,
		isOpen,
		onCancel,
		onRestore,
        lineItemId,
        parentLineItemId,
		restoreLoading
	} = props
	const classes = RestoreModalStyles();
    const [payload, setPayload] = React.useState({lineItemId, parentLineItemId, restoreReason: RESTORE_REASONS[0]});

    const getOptions = () => {
        return RESTORE_REASONS?.map((item, idx) => {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        })
      }
    
      const handleChange = (propertyName, value) => {
        setPayload((prevPayload) => ({...prevPayload, [propertyName]: value}));
      }


	/*
	*Function to set the Export Selection Type
	*/

	return (
		<Modal
			isOpen={isOpen}
			onClose={onCancel}
			actions={<RestoreModalActions onCancel={onCancel} onRestore={onRestore} payload={payload} restoreLoading={restoreLoading}/>}
			size="medium"
			title={
				<>
					<div>{`Restore ${title}`}</div>
				</>
			}
			data-testid="Restore-Modal"
		>
			<Grid>
					<GridColumn className={classes.fieldStyle}>
					<FieldLabel label={`Please select a reason to restore the ${title} to its original/previous state`} isRequired={true} />
                    <Select
                        value={payload?.restoreReason}
                        onChange={(v) => handleChange("restoreReason", v.target.value)}
                        id="restoreReason"
                        placeholder={"Please select a reason"}
                        selectProps={{
                        name: "restoreReason",
                        required: true
                        }}
                        data-testid="restore-reason"
                    >
                        {getOptions()}
                    </Select>
					</GridColumn>
					<GridColumn className={classes.fieldStyle}>

                    <FieldLabel label="Comment" />
                    <TextArea
                        name="restoreComment"
                        textAreaProps={{
                            name: "restoreComment"
                        }}
                        onChange={(e) => {handleChange("restoreComment", e.target.value)}}
                        value={payload?.restoreComment}
                        textFieldProps={{
                            name: "restoreComment"
                        }}
                        data-testid="restore-comment"
                    />                    
                </GridColumn>
			</Grid>
		</Modal>
	)
}

export default RestoreModal