import { createUseStyles } from "react-jss";
import { SPACING } from "../../../../constants/styleGuide";

const border = '1px solid #F1F1F2';
export const InvoiceLineItemDetailStyles = createUseStyles({
	rightText: {
		textAlign: 'left',
		paddingTop: "12px",
		paddingLeft: "100px",
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "20px",
		fontFamily: "Bogle"
	},
	leftText: {
		textAlign: 'left',
		paddingTop: "12px",
		fontSize: "14px",
		fontWeight: "700",
		lineHeight: "20px",
		fontFamily: "Bogle"
	},
	divider: {
		paddingTop: "10px",
		paddingBottom: "10px",
		marginLeft: "-15px",
		marginRight: "-15px"
	},
	container: {
		width: "100%"
	},
	breadcrumbWrapper: {
		padding: "20px"
	},
	leftWrapper: {
		paddingTop: SPACING.s20,
		paddingLeft: SPACING.s24,
		paddingRight: SPACING.s24,
		paddingBottom: SPACING.s20,
		minHeight: "1200px",
	},
	rightWrapper: {
		paddingTop: SPACING.s8,
		paddingLeft: SPACING.s24,
		paddingRight: SPACING.s24,
		paddingBottom: SPACING.s24,
	},
	subHeading: {
		padding: "0px 0px 20px 20px"
	},
	lineItemDetail: {
		backgroundColor: '#ffffff',
		borderRadius: "8px",
		padding: "20px 20px 10px 20px",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
		marginBottom: "15px",
		marginTop: '10px',
	},
	whiteRow: {
		background: '#fff',
		fontWeight: 'bold',
		padding: '10px',
		marginBottom: '10px',
		color: '#2E2F32',
	},
	greyRow: {
		background: '#F8F8F8',
		borderRadius: 5,
		border: border,
		padding: '15px',
		marginBottom: '10px',
		color: '#2E2F32'
	},
	lineItemRightText: {
		textAlign: "right",
	},
	versionHeader: {
		padding: '15px',
		marginTop: "20px",
		color: '#2E2F32',
		fontWeight: "700",
	},
	currentVersionDetail: {
		background: '#F2F8FD',
		borderRadius: 5,
		border: '2px solid #0071DC',
		padding: '15px',
		marginBottom: '10px',
		color: '#2E2F32',
	},
	previousVersionDetail: {
		background: '#FFF',
		borderRadius: 5,
		padding: '15px',
		marginBottom: '10px',
		color: '#2E2F32',
	},
	button: {
		background: '#fff',
		fontWeight: 'bold',
		padding: '5px 15px 0px 0px',
		color: '#2E2F32',
		textAlign: "right"
	},
	editIcon: {
		marginRight: '18px',
		cursor: 'pointer',
		border: 'none',
    	background: 'none'
	},
	editCancel: {
		marginRight: '18px',
		cursor: 'pointer',
		fontSize: '15px',
		fontWeight: 'bold',
		border: 'none',
		background: 'none'
	},
	greyRowForEdit: {
		background: '#F8F8F8',
		borderRadius: '0 0 5px 5px',
		borderTop: border,
		padding: '5px 15px 15px 15px',
		color: '#2E2F32',
		marginLeft: '-15px',
		marginRight: '-15px',
		marginBottom: '-15px',
		marginTop: '15px'
	},

	modalWhiteRow: {
		background: '#fff',
		fontWeight: 'bold',
		borderRadius: 5,
		border: border,
		padding: '15px',
		marginTop: '10px',
		color: '#2E2F32',
		'& div > label': {
			fontWeight: 'normal',
			height: 0
		},
		'& div > svg': {
			top: '1rem'
		}
	},
	accordionText: {
		margin: "10px 5px 5px 5px",
		fontWeight: "bold"
	},

	lineItemText: {
		padding: "5px 0px"
	},
	metaDataText: {
		paddingTop: "10px"
	},
	lineItemHeading: {
		paddingBottom: "10px"
	},
	discardButtons: {
		marginRight: "16px",
	},
	unsaveSection: {
		padding: "26px 48px 26px 24px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: "#002d58",
	},
	unsaveText: {
		color: "#ffffff",
		margin: "0",
		fontSize: "20px",
		lineHeight: "1.4",
	},
	spinner: {
		width: "20px"
	},
	centerDiv: {
		display: "flex", 
		alignItems: "center"
	},
	restoreText: {
		marginRight: "3px"
	},
	inputWrap:{
		padding: "10px 0",
		"& div>div>input":{
			textAlign: 'right'
		}
	},
	rightInputWrap:{
		marginLeft: '5px'
	},
	leftInputWrap:{
		marginRight: '5px'
	},
	lineItemLabel : {
		fontWeight: "normal",
		padding: "5px 0px"
	},
	dividerStyle : {
		marginTop: "10px",
	}
});
